<template>
  <el-dialog title="选择产品" width="1400px" class="order-product" :visible.sync="visible" :close-on-click-modal="false"
    :before-close="close">
    <el-tabs v-model="ruleForm.isUne" @tab-click="handleClick">
      <el-tab-pane label="可用库存" name="0"></el-tab-pane>
      <el-tab-pane label="不可用库存" name="1"></el-tab-pane>
    </el-tabs>
    <div class="search-wrapper">
      <div class="left">
        <el-input placeholder="请输入产品名称/SKU ID/规格" v-model="ruleForm.queryName" maxlength="50" clearable></el-input>
        <el-button @click="search">搜索</el-button>
      </div>
      <div class="right">
        <el-radio-group v-model="ruleForm.isShowZero">
          <el-radio :label="0">显示全部</el-radio>
          <el-radio :label="2">只显示库区库位在仓数为0的数据</el-radio>
          <el-radio :label="1">不显示库区库位在仓数为0的数据</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="container">
      <el-table border :data="tableData" ref="multipleTable" max-height="450" @selection-change="changeFun">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column align="center" label="产品图片" prop="productCode" width="120">
          <template slot-scope="scope">
            <erp-image :imgs="scope.row.productImage"></erp-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品编码" prop="productCode" width="120">
          <template slot-scope="scope">
            <div class="page-link" @click="goDetail(scope.row)">
              {{ scope.row.skuId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" label="产品名称" prop="productName" />
        <el-table-column width="140" align="center" label="英文规格" prop="productSpec">
          <template slot-scope="scope">
            {{  scope.row.productSpec || '--' }}
          </template>
        </el-table-column>
        <el-table-column width="140" align="center" label="中文规格" prop="productSpecCn">
          <template slot-scope="scope">
            {{  scope.row.productSpecCn || '--' }}
          </template>
        </el-table-column>

        </el-table-column>
        <el-table-column width="120" align="center" label="产品类别" prop="productType" />

        <el-table-column width="100" align="center" label="在仓数" prop="num">
        </el-table-column>
        <el-table-column width="120" align="center" prop="enableNum">
          <template slot="header">
            <div>
              <span>可用库位数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" align="center" prop="disableNum">
          <template slot="header">
            <div>
              <span>不可用库位数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  该库位不可用库存。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="fullLocationNumber" label="库区库位号" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :class="{
              'page-link-noline':scope.row[ruleForm.isUne == 0  ? 'enableNum' :'disableNum'] === 0
            }" @click="selectLoc(scope.row)">
              {{
              scope.row.fullLocationNumber
              }}
            </span>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination :page-sizes="[10, 20, 30, 40, 50, 100, 200]" @current-change="handleCurrentChange"
      @size-change="sizeChange" :current-page="pageNo" :page-size="pageSize" :total="total"
      layout="total, sizes,  prev, pager, next, jumper"></el-pagination>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确定</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
    <!-- 选择库位 -->
    <BaseLocChoose ref="baseLocChoose" :params="{
      show:locShow
    }" @close="locShow = false" :locList="locList" @set-loc="setLoc" />
  </el-dialog>
</template>

<script>
  import { StoreCheck, ProductListInteractor, InboundSheet } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import BaseLocChoose from '@/components/BaseLocChoose.vue' // 选择库位
  import { deepCopy } from 'kits'
  export default {
    props: {
      warehouseId: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableData: [],
        pageNo: 1,
        pageSize: 10,
        total: 0,
        ruleForm: {
          codeOrName: '',
          isShowZero: 0,
          isUne: '0',
        },
        multipleSelection: [],
        locShow: false,
        locList: []
      }
    },
    components: {
      BaseLocChoose
    },

    watch: {
      visible(newval, oldval) {
        if (newval) {
          this.getProductList()
        }
      },
      "ruleForm.isShowZero": function (val) {
        this.handleClick()
      }
    },
    methods: {
      setLoc(locs) {
        this.currentProduct.locSelected = locs
        this.currentProduct.fullLocationNumber = locs.map(s => s.fullLocationNumber).join(',')
      },
      // 选择库位
      selectLoc(product) {
        let num = this.ruleForm.isUne == 0 ? product.enableNum : product.disableNum
        if (num !== 0) {
          return
        }
        this.currentProduct = product
        let warehouseId = this.warehouseId, flag = 1
        // 获取库位下拉数据
        InboundSheet.findWarehouseLocation({
          warehouseId,
          skuId: product?.skuId,
          flag
        }).then(res => {
          if (res?.code === '000000') {

            this.locList = res?.data || []
            this.locShow = true

            let {
              fullLocationNumber,
              locationNumber,
              warehouseId,
              warehouseLocationId
            } = product
            let chooseLoc = [{
              fullLocationNumber,
              locationNumber,
              warehouseId,
              warehouseLocationId
            }]
            if (product.locSelected?.length > 0) {
              chooseLoc = product.locSelected
            } else {

            }
            this.$refs.baseLocChoose.locs = chooseLoc
          }
        })


      },
      sizeChange(val) {
        this.pageSize = val
        this.pageNo = 1
        this.getProductList()
      },
      // 切换tab
      handleClick() {
        this.pageNo = 1
        this.getProductList()
      },
      // 获取列表
      async getProductList() {
        const params = {
          pageNo: this.pageNo,
          pageLe: this.pageSize,
          ...this.ruleForm,
          warehouseId: this.warehouseId,
        }
        let res = await StoreCheck.choseProductList(params)
        this.tableData = res.data.data
        this.total = res.data.total
      },

      // 切换分页
      handleCurrentChange(val) {
        this.pageNo = val
        this.getProductList()
      },

      // 搜索
      search() {
        this.pageNo = 1
        this.getProductList()
      },

      // 数据格式处理
      formatColumn(type, value1, value2) {
        const arr = type.split(',')
        if (!arr[0]) {
          return '/'
        } else if (arr[0] && arr.length == 1) {
          return arr + ':' + value1
        } else {
          return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
        }
      },

      // 选中
      changeFun(rows) {
        this.multipleSelection = rows
      },
      // 根据产品选择的库位（不是默认带出来的处理数据)
      extractTableData(tableData) {
        tableData = deepCopy(tableData)
        let arr = []
        tableData.forEach((item) => {
          if (item.locSelected?.length > 0) {
            item.locSelected.forEach((it) => {
              arr.push({
                ...item,
                fullLocationNumber: it.fullLocationNumber,
                locationNumber: it.locationNumber,
                warehouseLocationId: it.warehouseLocationId,
              })
            })
          } else {
            arr.push({
              ...item
            })
          }
        })

        return arr
      },
      // 确认
      save() {
        if (!this.multipleSelection.length) {
          this.$message.warning('至少选择一条产品')
          return false
        }
        this.multipleSelection = this.multipleSelection.map((it) => {
          return {
            ...it,
            usable: this.ruleForm.isUne == 0 ? 1 : 0,
            // num: this.ruleForm.isUne == 0 ? it.enableNum : it.disableNum,
          }
        })

        this.$emit('confirm', this.extractTableData(this.multipleSelection))
        this.$emit('update:visible', false)
        this._reset()
      },

      // 关闭
      close() {
        this.$emit('update:visible', false)
        this._reset()
      },
      // 重置
      _reset() {
        this.pageNo = 1
        this.pageLe = 10
        this.ruleForm.codeOrName = ''
        this.ruleForm.isShowZero = 0
        this.ruleForm.isUne = '0'
      },
      async goDetail(row) {
        let id = row.productCode.split('-')[0]
        //根据产品code，查询产品id
        // 辅料不能跳转
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          navigateTo(this, {
            pageType: PAGE_TYPES.productDetail,
            roleJudge: false,
            proId: response.data,
            productCode: row.productCode,
            query: {},
          })
        }
        // },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .order-product {
    .container {
      height: 450px;
      overflow-y: auto;
    }
  }

  .mr15 {
    margin-right: 15px;
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .left {
      .el-input {
        width: 250px;
        margin-right: 20px;
      }
    }

    .right {
      flex: 1;
      text-align: right;
    }
  }

  .search-wrapper .el-pagination {
    margin-top: 10px;
  }
</style>
